/******************************************************************************
 *
 *  (C) 2022 AhnLab, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/

<template>
<v-footer padless absolute height="100%">
    <v-card
        flat
        tile
        width="100%"
        class="grey lighten-3 text-center">
        <v-card-text class="footer-txt">
            AhnLab Open Source Release System 3.3.1.1_1
            <br>
            © AhnLab, Inc. All rights reserved.
            <br>
            <v-btn
                text
                rounded
                @click="mail()">
                <font-awesome-icon icon="fa-solid fa-envelope"/>&nbsp;Contact Us
            </v-btn>
        </v-card-text>
    </v-card>
</v-footer>
</template>

<script>
export default {
    methods: {
        mail: function () {
            window.location.href = 'mailto:opensource@ahnlab.com'
        }
    } 
}
</script>

<style>

</style>